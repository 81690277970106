export const colorsFloor = [
    0.9581782400495603,
    0.8729028855144576,
    0.9818432693592081,
    0.7166764737883429,
    0.8090212314562105,
    0.9595252105411918,
    0.7651486977632846,
    0.8120981468933653,
    0.9664498139661837,
    0.9674319562423626,
    0.9948890003239883,
    0.9953474223203376
]

// Color generator
// const colorsFloor = [];
// const color = new THREE.Color();

// for (let i = 0, l = position.count; i < l; i++) {
//   color.setHSL(Math.random() * 0.3 + 0.5, 0.75, Math.random() * 0.25 + 0.75);
//   colorsFloor.push(color.r, color.g, color.b);
// }
// console.log(colorsFloor);